import {BarDetails, BarWithDeals, sortedDeals} from "../../interfaces/IBar";
import {HeaderWithBackground, PageContentCard} from "./HeaderWithBackground";
import {useTranslations} from "next-intl";
import {Flex, Heading, IconButton, ListItem, Text, UnorderedList, VStack} from "@chakra-ui/react";
import {CardSubtitle} from "react-bootstrap";
import BarLink from "./BarLink";
import {EditIcon} from "@chakra-ui/icons";
import {Link} from "../../i18n/routing";
import {groupConsecutiveDaysByWorkingHours} from "../../tools/groupHours";
import {DailyOrDayList} from "./DailyOrDayList";
import {TimePeriodView} from "./TimePeriodView";
import {BarFeatures} from "./BarFeatures";

export function BarOverview(
    {bar, details}: {bar: BarWithDeals, details?: BarDetails}
) {

    const t = useTranslations("bar")
    const tHome = useTranslations("Home")
    return (
        <HeaderWithBackground title={t("title", {bar: bar.name})} bg={"/images/beer.webp"} fullHeight={true}>
            <PageContentCard header={
                <Flex justifyContent="space-between" alignContent="top">
                    <VStack align="start" mt={2}>
                        <Heading size="lg">{bar.name} {details && <BarFeatures details={details}/>}</Heading>
                        <CardSubtitle>{bar.address}</CardSubtitle>
                        <BarLink bar={bar}/>
                    </VStack>
                    <Link href={{pathname: "/addbar/" + bar.id}} rel="nofollow">{<IconButton borderRadius="50%" aria-label={tHome("suggestEdit")} icon={<EditIcon/>}></IconButton>}</Link>
                </Flex>
            }>

                <Heading size="md">{t("happyhours")}</Heading>
                <VStack spacing={5} align="start">
                    <UnorderedList mb={5}>
                        {sortedDeals(bar.deals).map(deal => (
                            <ListItem key={deal.id}>
                                <Text>
                                    <b>{deal.description}</b><br/>

                                    {groupConsecutiveDaysByWorkingHours(deal.days).map(((a, idx) => (
                                        <>
                                            <DailyOrDayList schedule={a}/>
                                            {" "}
                                            <TimePeriodView period={a.hours}/>
                                        </>
                                    )))}
                                </Text>
                            </ListItem>
                        ))}
                    </UnorderedList>
                </VStack>
                <iframe width="100%" height="450" loading="lazy" allowFullScreen
                        src={`https://www.google.com/maps/embed/v1/place?q=place_id:${bar.id}&key=${process.env.NEXT_PUBLIC_MAPS_APIKEY}`}></iframe>
            </PageContentCard>
        </HeaderWithBackground>
    )
}
