'use client'

import {
    Badge,
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Flex,
    GridItem,
    Heading,
    LinkBox,
    SimpleGrid,
    Text,
    VStack
} from '@chakra-ui/react'
import moment from 'moment'
import "moment/locale/lv"
import "moment/locale/en-gb"
import {findHappeningLater, findHappeningNow} from '../../interfaces/IWeekdayTime'
import HappeningNowView from "../../components/ui/happeningnow";
import {useMemo} from "react";
import {fromSerializable, IMomentDealSerializable} from "../../interfaces/IMomentDeal";
import {BarDetails, BarWithDeals, ChainWithDeals, sortedDealsNullable} from "../../interfaces/IBar";
import {IDeal} from "../../interfaces/IDeal";
import PageLayout from "../../components/ui/layout";
import BarCard from "../../components/ui/bar";

import 'moment/locale/en-gb';
import 'moment/locale/lv';
import {useLocale, useTranslations} from "next-intl";
import {Link} from "../../i18n/routing";
import {addRegionToLocale} from "../../components/tools/momentLocale";
import {BarOverview} from "../../components/ui/BarOverview";
import DealView from "../../components/ui/DealView";
import {LinkOverlayToDealOwner} from "../../components/ui/LinkOverlayToDealOwner";
import {getShortBarName, groupDeals} from "../../components/tools/groupDeals";


interface IProps {
    bars: BarWithDeals[],
    chains: ChainWithDeals[],
    upcomingOrHappening: IMomentDealSerializable[],
    focusBar?: BarWithDeals,
    barDetails?: BarDetails|undefined
}

export default function HomePage({ bars, chains, upcomingOrHappening, focusBar, barDetails }: IProps) {
    const locale = useLocale()
    console.log("Client locale", locale)
    moment.locale(addRegionToLocale(locale))

    const _upcomingOrHappening = useMemo(() => {
        return upcomingOrHappening.map(a => fromSerializable(a))
    }, [upcomingOrHappening])

    const happeningNow = useMemo(() => {
        return findHappeningNow(_upcomingOrHappening)
    }, [_upcomingOrHappening])

    const upcoming = useMemo(() => {
        return findHappeningLater(_upcomingOrHappening)
    }, [_upcomingOrHappening])

    const t = useTranslations("Home")
    const tChain = useTranslations("chain")

    return (
        <Box>
            {
                focusBar ? <BarOverview bar={focusBar} details={barDetails}/> : (
                    <PageLayout city='Riga' happeningNow={happeningNow} upcoming={upcoming}>
                        <Box m="20px">
                            {happeningNow && happeningNow.length > 0 && (
                                <VStack>
                                    <HappeningNowView marginX="0px" happeningNow={[]} upcoming={upcoming}/>
                                </VStack>
                            )}
                            <Flex alignItems="baseline" justifyContent="start" marginY="20px" data-nosnippet>
                                <Heading size="md">{t("allBars")}</Heading>
                                <Link href={{pathname: "/addbar"}}><Button marginStart="20px" rel="nofollow">Contribute new bar</Button></Link>
                            </Flex>
                            <VStack gap="20px" align="stretch">
                                {chains.filter(chain => chain.deals.length > -1).map(chain => (
                                    <GridItem key={chain.id} w="100%">
                                        <LinkBox>
                                            <Card variant="filled" size="sm">
                                                <LinkOverlayToDealOwner owner={chain}>
                                                    <CardHeader>
                                                        <Heading size="md">{chain.name}</Heading>
                                                        <Text>{tChain("chain_title", {num: chain.size})}</Text>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <SimpleGrid spacing={5} minChildWidth="200px">
                                                            {sortedDealsNullable(chain)?.map((deal: IDeal) => <DealView key={deal.description} deal={deal}/>)}
                                                            {
                                                                groupDeals(bars.filter(b => b.chain?.id == chain.id)).map(dg => (
                                                                    <DealView key={dg.deal.id} deal={dg.deal} badge={(
                                                                        <Badge borderRadius="4px" textTransform="none" colorScheme="orange" w="max-content">
                                                                            Only {dg.bars.map(b => getShortBarName(b, chain)).join(", ")}
                                                                        </Badge>
                                                                    )}/>
                                                                ))
                                                            }
                                                        </SimpleGrid>
                                                    </CardBody>
                                                </LinkOverlayToDealOwner>
                                            </Card>
                                        </LinkBox>
                                    </GridItem>
                                ))}
                                <SimpleGrid minChildWidth="300px" justifyContent={{base: "left", lg: "space-around"}} spacing="20px">
                                    {bars.filter(bar => bar.deals.length > 0 && bar.chain == null).map((bar: BarWithDeals) => (
                                        <GridItem key={bar.id} gridAutoRows="max-content">
                                            <BarCard bar={bar}/>
                                        </GridItem>
                                    ))}
                                </SimpleGrid>
                            </VStack>
                        </Box>

                    </PageLayout>
                )
            }
        </Box>
    )
}