import {formatWeekdayList, Schedule} from "../../tools/groupHours";
import {useTranslations} from "next-intl";

export interface Props {
    schedule: Schedule
}

export function DailyOrDayList(
    props: Props
) {
    const t = useTranslations("Home")
        return (
            <>
                {props.schedule.days.length == 7 ? t("daily") : formatWeekdayList(props.schedule.days)}
            </>
        )
}