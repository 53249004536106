import {
    Badge,
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Flex,
    Heading,
    HStack,
    LinkBox,
    Text,
    VStack
} from "@chakra-ui/react";
import moment from "moment/moment";
import {getDealOwnerLink, IMomentDeal} from "../../interfaces/IMomentDeal";
import {useTranslations} from "next-intl";
import {Moment} from "moment";
import {Conditions} from "./Conditions";
import {Link} from "../../i18n/routing";
import {LinkOverlayToDealOwner} from "./LinkOverlayToDealOwner";

function format(data: Moment, isAfterMidnight: boolean): string {
    const reference = moment().add(1, 'day').startOf('day')
    return isAfterMidnight ? data.calendar(reference) : data.calendar()
}

export default function HappeningNowView(
    { happeningNow, upcoming, wrap=true, color, marginX="20px" }: { happeningNow: IMomentDeal[], upcoming: IMomentDeal[], wrap?: boolean, color?: string, marginX?: string }
) {

    // const today = upcoming.filter(d => d.today)
    // const tomorrow = upcoming.filter(d => d.tomorrow)

    const minCardWidth = "200px"
    const maxCardWidth = "40vw"
    const t = useTranslations("Home")
    return (
        <Box w="100%" data-nosnippet>
            <Flex direction="row" flexWrap={wrap ? "wrap" : "nowrap"} overflowX="auto" gap={4}>
                {happeningNow.map((now) => (
                    // Happening now
                    <Card size="sm" borderRadius="20px" variant="filled" minW={minCardWidth} maxW={maxCardWidth} minH="250px" ml={marginX} key={now.day + now.description + now.owner.name} flexGrow={999}>
                        <CardHeader>
                            <Heading size="md">{now.description}</Heading>
                            <Conditions conditions={now.conditions}/>
                            <Badge p="5px" colorScheme="orange">
                                {t("endsAt", {when: now.times[1].format("LT")})}
                            </Badge>
                        </CardHeader>
                        <CardFooter h="100%">
                            <HStack h="100%" align="end">
                                <Link href={{pathname: "/" + getDealOwnerLink(now.owner)}}><Text color="teal.500">{now.owner.name}</Text></Link>
                            </HStack>
                        </CardFooter>
                    </Card>
                ))}
                {
                    (happeningNow.length == 0) && upcoming.flatMap((deal, idx) => {
                        // Upcoming
                        const previousToday = upcoming[idx - 1]?.today == true
                        const previousTomorrow = upcoming[idx - 1]?.tomorrow == true
                        const today = deal.today && !previousToday;
                        const tomorrow = deal.tomorrow && !previousTomorrow;
                        const later = !deal.tomorrow && !deal.today && (previousToday || previousTomorrow || idx == 0);
                        return [
                            <>
                            {wrap && (tomorrow || today || later) && (
                                    <Heading w="100%" size="md">
                                        {today ? t("today") : tomorrow ? t("tomorrow") : later ? t("later") : ""}
                                    </Heading>
                            )}
                            </>,
                            (<Box key={deal.description + deal.owner.name + deal.times[1].toISOString() + deal.times[0].toISOString()} ml={marginX} flexShrink={0} flexGrow={999} maxW={maxCardWidth} minW={minCardWidth}>
                                    <LinkBox>
                                        <LinkOverlayToDealOwner owner={deal.owner}>
                                            <Card variant="outline" size="sm" borderRadius="20px" minH="250px">
                                                <CardHeader>
                                                    <Heading size="md">{deal.description}</Heading>
                                                    <Conditions conditions={deal.conditions}/>
                                                </CardHeader>
                                                <CardBody>
                                                    <VStack w="100%" align="start">
                                                        <Text as="b">{format(deal.times[0], deal.afterMidnight.start)}{deal.afterMidnight.start && <sup>+1</sup>} - {deal.times[1].format("LT")}{deal.afterMidnight.end && <sup>+1</sup>}</Text>
                                                        <Badge colorScheme="green" fontSize="xs">{t("starts")} {deal.times[0].fromNow()}</Badge>
                                                    </VStack>
                                                </CardBody>
                                                <CardFooter h="100%">
                                                    <HStack h="100%" align="end">
                                                        <Text color="teal">{deal.owner.name}</Text>
                                                    </HStack>
                                                </CardFooter>
                                            </Card>
                                        </LinkOverlayToDealOwner>
                                    </LinkBox>
                            </Box>)
                            ]
                    })
                }
            </Flex>
        </Box>
    )
}

function UpcomingGrid(
    {when, deals}: {when: "today"|"tomorrow", deals: IMomentDeal[]}
) {

}