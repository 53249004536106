import {Moment} from "moment";
import {BarChain, IBar} from "./IBar";
import moment from "moment/moment";

/**
 * IDeal instance with start and end times as Moment objects and weekday as a localized string.
 */
export interface IMomentDeal {
    description: string,
    conditions: string,
    times: Moment[],
    day: string,
    owner: DealParent,
    afterMidnight: AfterMidnight,
    today: boolean,
    tomorrow: boolean
}

export interface DealParent {
    name: string
}

export function getDealOwnerLink(owner: DealParent): string {
    function isBar(object: any): object is IBar {
        return 'address' in object;
    }

    function isChain(object: any): object is BarChain {
        return !('address' in object);
    }
    return isBar(owner) ? owner.id : (isChain(owner) ? "c/" + owner.id : "")
}

export interface IMomentDealSerializable extends Omit<IMomentDeal, "times"> {
    times: string[]
}

export function toSerializable(deal: IMomentDeal): IMomentDealSerializable {
    return {
        owner: deal.owner,
        today: deal.today,
        afterMidnight: deal.afterMidnight,
        conditions: deal.conditions,
        day: deal.day,
        tomorrow: deal.tomorrow,
        description: deal.description,
        times: [deal.times[0].toISOString(), deal.times[1].toISOString()]
    }
}

export function fromSerializable(deal: IMomentDealSerializable): IMomentDeal {
    return {
        owner: deal.owner,
        today: deal.today,
        afterMidnight: deal.afterMidnight,
        day: deal.day,
        conditions: deal.conditions,
        tomorrow: deal.tomorrow,
        description: deal.description,
        times: [moment(deal.times[0]), moment(deal.times[1])]
    }
}

export interface AfterMidnight {
    start: boolean,
    end: boolean
}