import {DealParent, getDealOwnerLink} from "../../interfaces/IMomentDeal";
import {useLocale} from "next-intl";
import {ReactNode} from "react";
import {LinkOverlay} from "@chakra-ui/react";

export function LinkOverlayToDealOwner(
    {owner, children}: {owner: DealParent, children: ReactNode}) {
    const locale = useLocale()

    return (
            <LinkOverlay href={"/" + locale + "/" + getDealOwnerLink(owner)}>
                {children}
            </LinkOverlay>
    )
}