import {Bar, BarChain, BarWithDeals} from "../../interfaces/IBar";
import {BarDeal} from "../../interfaces/IDeal";
import groupBy from "lodash/groupBy"
import {Md5} from "ts-md5";

export interface DealGroup {
    deal: BarDeal,
    bars: Bar[]
}

interface BarAndDeal {
    bar: Bar,
    deal: BarDeal,
    dealHash: string
}

export function groupDeals(bars: BarWithDeals[]): DealGroup[] {
    const barDeals = bars.flatMap(b => {
        return b.deals.map(d => {
            const deal = {...d} as any
            delete deal["barId"]
            delete deal["id"]
            return {
                bar: b,
                deal: d,
                dealHash: Md5.hashStr(JSON.stringify(deal))
            } as BarAndDeal
        })
    })

    const grouped = groupBy(barDeals, item => item.dealHash)

    return Object.entries(grouped).map(([hash, data]) => {
        return {deal: data[0].deal, bars: data.map(d => d.bar)} as DealGroup
    })
}

export function getShortBarName(bar: Bar, chain: BarChain) {
    return bar.name.replace(chain.name, '').replace(')', '').replace('(', '').trim()
}