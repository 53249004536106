import {INewDeal} from "../../interfaces/IDeal";
import {Card, Heading, Table, Tbody} from "@chakra-ui/react";
import DealTime from "./dealtime";
import {Conditions} from "./Conditions";
import {ReactNode} from "react";

export default function DealView(
    {deal, stretch=false, badge}: {deal: INewDeal, stretch?: boolean, badge?: ReactNode}
) {
    return (
        <Card variant="outline" size="md" borderRadius="15px" key={deal.description} p={3} w={stretch ? "100%" : undefined}>
            <Heading size="sm">{deal.description}</Heading>
            <Conditions conditions={deal.conditions}/>
            {badge}

            <Table size="sm">
                <Tbody>
                    <DealTime days={deal.days}/>
                </Tbody>
            </Table>

        </Card>
    )
}