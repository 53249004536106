import {Td, Text, Tr} from "@chakra-ui/react"
import {groupConsecutiveDaysByWorkingHours, WorkingHoursMap} from "../../tools/groupHours";
import {TimePeriodView} from "./TimePeriodView";
import {DailyOrDayList} from "./DailyOrDayList";

export default function DealTime({
    days
  }: {
    days: WorkingHoursMap
  }) {
    const periods = groupConsecutiveDaysByWorkingHours(days)
    return (
                
        periods.map(period => {
            return (
                <Tr key={period.hours[0] + period.hours[1] + period.days[0]}>
                    <Td><DailyOrDayList schedule={period}/></Td>
                    <Td><Text align="right" m={0} p={0}><TimePeriodView period={period.hours}/></Text></Td>
                </Tr>
            )
        })
    )
  }