import {Box, Center, CircularProgress, Stack} from "@chakra-ui/react";
import React, {ReactNode} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HappeningNowView from "./happeningnow";
import {IMomentDeal} from "../../interfaces/IMomentDeal";
import {useTranslations} from "next-intl";
import {HeaderWithBackground} from "./HeaderWithBackground";

export default function PageLayout(
    {city, happeningNow, upcoming, children}: {city: string, happeningNow: IMomentDeal[] | undefined, upcoming: IMomentDeal[], children: ReactNode}
  ) {
    const t = useTranslations("Home")
    return (
        <Stack >
            <HeaderWithBackground title={t("title")} bg="/images/beer.webp" applyMarginToBody={false}>
                {happeningNow ? <HappeningNowView wrap={false} color="white" happeningNow={happeningNow} upcoming={upcoming}/> : <Center><CircularProgress isIndeterminate={true}/></Center>}
            </HeaderWithBackground>
            <Box>
                {children}
            </Box>
        </Stack>
    )
  }