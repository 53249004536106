"use client"
import {BarWithDeals} from "../../interfaces/IBar";
import {Box, Button, Link} from "@chakra-ui/react";
import NextLink from "next/link";
import {ExternalLinkIcon} from "@chakra-ui/icons";
import {useTranslations} from "next-intl";

export default function BarLink(
    {bar}: {bar: BarWithDeals}
) {
    const link = `https://www.google.com/maps/search/?api=1&query=${bar.address}&query_place_id=${bar.id}`
    const t = useTranslations("Home")
    return (
        <Box data-nosnippet>
            <Link rel="nofollow" isExternal as={NextLink} color='teal.500' href={link}>
                <Button rightIcon={<ExternalLinkIcon/>} variant="solid">{t("openMap")}</Button>
            </Link>
        </Box>
    )
}