import moment, {Moment} from "moment";
import {WithDeals} from "./IBar";
import {DealParent, IMomentDeal} from "./IMomentDeal";
import {WorkingHoursMap} from "../tools/groupHours";

export interface IWeekdayTime {
    weekDayName: string,
    fromTime: string,
    toTime: string,
}

function numberToMoment(time: number, weekday: number): Moment {
    return moment((time%2400).toString().padStart(4, '0'), "HHmm").day(weekday).add(Math.floor(time / 2400), 'day')
}

export function findNextOccurrence(day: number, startTime: number, endTime: number): Moment[] {
    const endTimeOnThisDayThisWeek = numberToMoment(endTime, day)

    const upcomingStart = endTimeOnThisDayThisWeek < moment() ?
        numberToMoment(startTime, day).add(1, "week")
        : numberToMoment(startTime, day)

    const upcomingEnd = endTimeOnThisDayThisWeek < moment() ?
        numberToMoment(endTime, day).add(1, "week")
        : numberToMoment(endTime, day)

    return [
        upcomingStart,
        upcomingEnd
    ]
}

function closestStartTime(days: WorkingHoursMap): NextOccurrence | undefined {
    const now = moment()
    let day = now.day()
    let daysChecked = 0
    let fallback: NextOccurrence|undefined = undefined
    while (true) {
        if (daysChecked == 7) break
        if (days[day]) {
            const [start, end] = days[day]
            // const afterMidnight = start > 2359
            const next = findNextOccurrence(day, start, end)
            if (next[0].week() == now.week()) {
                return {
                    day: day,
                    startTime: start,
                    endTime: end,
                    time: next
                }
            } else {
                if (!fallback || (fallback && next[0] < fallback.time[0])) {
                    fallback = {
                        day: day,
                        startTime: start,
                        endTime: end,
                        time: next
                    }
                }
            }
        }
        day = (day + 1) % 8
        daysChecked++
    }
    return fallback
}

interface NextOccurrence {
    day: number,
    time: Moment[],
    startTime: number,
    endTime: number
}

export function withMomentDates(owners: WithDeals<any>[]): IMomentDeal[] {
    // Convert list of IDeal to IMomentDeal (deal with start and end as moment instances of next occurrence)
    // Unique by a deal, with the closest occurrence (by time) returned.
    const now = moment().startOf('day')
    return owners
        .flatMap((owner => {
            return owner.deals?.flatMap((deal) => {
                // const withNextOccurrences = Object.entries(deal.days).map(value => {
                //     return {day: value[0], time: findNextOccurrence(+value[0], value[1][0], value[1][1]), startTime: value[1][0], endTime: value[1][1]}
                // })
                // const nextOccurrence = minBy(withNextOccurrences, value => value.time[0])
                const nextOccurrence = closestStartTime(deal.days)

                if (nextOccurrence) {
                    const nextDayStart = nextOccurrence.startTime > 2359

                    const nextDayEnd = nextOccurrence. endTime > 2359
                    const moments = nextOccurrence.time
                    const todayTomorrowReference = nextDayStart ? now.clone().add(1, 'day') : now.clone()

                    const simpleOwner = {...owner} as DealParent
                    delete simpleOwner["deals"]

                    return [{
                        times: moments,
                        owner: simpleOwner,
                        day: moments[0].format("dddd"),
                        description: deal?.description,
                        conditions: deal?.conditions,
                        afterMidnight: {
                            start: nextDayStart,
                            end: nextDayEnd
                        },
                        today: moments[0].day() == todayTomorrowReference.day() && moments[0].week() == todayTomorrowReference.week(),
                        tomorrow: moments[0].clone().startOf('day').toISOString() == todayTomorrowReference.clone().add(1, 'day').toISOString()
                    } as IMomentDeal]
                } else return []
            }) ?? []
        }))

}

export function sortDeals(deals: IMomentDeal[]): IMomentDeal[] {
    return deals.sort((deal1, deal2) => {
        if (deal1.times[0] >= deal2.times[0]) return 1
        else return -1
    })
}

export function findHappeningNow(deals: IMomentDeal[]): IMomentDeal[] {
    const now = moment()
    return deals.filter(deal => deal.times[0] <= now && now < deal.times[1])
}

export function findHappeningLater(deals: IMomentDeal[]): IMomentDeal[] {
    const now = moment()
    return deals.filter(deal => deal.times[0] > now)
}
